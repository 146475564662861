import { ExitToApp } from '@mui/icons-material';
import { AppBar, CardMedia, Divider, IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Location } from 'history';
import { Redirect, Route, Switch, useRouteMatch, useHistory } from 'react-router';
import { logout } from '../../redux-store/actions/auth';
import LoginOtp from '../auth/loginOtp';
import { Footer } from '../commonComponents';
import { Routes } from '../investors/routes';
import InvestorApplication from './investor-application';
import { useDispatch, useSelector } from 'react-redux';
import { APMIFlow, projectIsProd, USER_ROLES } from '../../utils/constant';
import { RootStateType } from '../../redux-store/reducers';
import LoginRequireRoute from '../../routes/LoginRequireRoute';
import MobileLogin from '../auth/MobileLogin';
import InvestmentDetails from './investor-details';
import InvestorApplicationBeforeFaceCapture from '../geoLocationAndFaceCapture.tsx/investorApplicationEdit';
import WebcamCapture, {
  CapturedDetailsSucsessfully,
} from '../geoLocationAndFaceCapture.tsx/faceCapture';
import LocationCapture from '../geoLocationAndFaceCapture.tsx/locationCapture';
import { useEffect, useState } from 'react';
import {
  investorEditDocumentDetailsObj,
  investorEditEndpointExcludingDoc,
  investorEditEndpointIncludingDoc,
} from '../investors/components';

export const EditApplication = ({
  location,
}: {
  location: Location<{ id: string; applicant1ReferenceId?: string }>;
}): JSX.Element => {
  const { id, applicant1ReferenceId } = location.state || {};
  const dispatch = useDispatch();
  const history = useHistory();
  const [logOutLoading, setLogOutLoading] = useState(false);
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);
  const { investorEditObj } = useSelector((store: RootStateType) => store.investorEditObj);

  useEffect(() => {
    if (
      referenceDetail.applicant_type &&
      (APMIFlow && referenceDetail.applicant_type === '1'
        ? investorEditObj?.find((menu) => menu.endPoint === investorEditDocumentDetailsObj.endPoint)
          ? !investorEditEndpointIncludingDoc.includes(
              history?.location?.pathname?.split('/')?.pop() || ''
            )
          : !investorEditEndpointExcludingDoc.includes(
              history?.location?.pathname?.split('/')?.pop() || ''
            )
        : !investorEditObj || (investorEditObj && investorEditObj.length === 0))
    ) {
      history.push(`/investment-details/${applicant1ReferenceId}/application-details`);
      return;
    }
  }, [investorEditObj]);

  return (
    <Box
      sx={{
        bgcolor: { xs: '', sm: 'rgba(238, 244, 251, 0.5)' },
      }}>
      <AppBar position="fixed" elevation={0} sx={{ bgcolor: 'common.white' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingRight: { xs: 0, sm: '48px' },
            paddingLeft: { xs: 0, sm: '48px' },
          }}>
          <CardMedia
            component="img"
            src="/images/motilal-logo.png"
            alt="Logo"
            sx={{ width: '120px', m: 0.5 }}
          />
          {/* {role !== USER_ROLES.INVESTOR && ( */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {!projectIsProd && (
              <Typography
                sx={{
                  fontWeight: 600,
                  color: '#BD2932',
                  fontSize: { xs: '12px', sm: '16px' },
                  pr: { xs: 0, sm: 1 },
                }}>
                UAT
              </Typography>
            )}
            <IconButton
              onClick={async () => {
                try {
                  setLogOutLoading(true);
                  await dispatch(logout());
                  setLogOutLoading(false);
                  history.push(
                    referenceDetail.esignReferenceId
                      ? `/investment-details/${referenceDetail.esignReferenceId}/details`
                      : '/login'
                  );
                } catch (e) {
                  setLogOutLoading(false);
                  console.error((e as Error).message);
                }
              }}
              disabled={logOutLoading}>
              <ExitToApp fontSize="large" sx={{ color: 'primary.main' }} />
            </IconButton>
          </Box>
          {/* )} */}
        </Box>
      </AppBar>
      <Divider sx={{ display: { xs: 'block', sm: 'none' } }} />
      <Box sx={{ py: 3, px: { xs: 2, sm: 10 }, mt: 10 }}>
        <Routes id={id} applicant1ReferenceId={applicant1ReferenceId} />
      </Box>
      {/* <Footer /> */}
    </Box>
  );
};

const InvestorRoutes = (): JSX.Element => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={path}
        render={(props: any) => (
          <Redirect to={`${path}/:referenceId/details`} from={props.location.state} />
        )}
      />
      <Route path={`${path}/:referenceId/details`} component={InvestmentDetails} />
      <Route path={`${path}/:referenceId/verify-otp`} component={LoginOtp} />
      <Route path={`${path}/:referenceId/application-details`} component={InvestorApplication} />
      <Route path={`${path}/edit-application`} component={EditApplication} />
      <Route path={`${path}/:referenceId/photo-capture`} component={WebcamCapture} />
      <Route path={`${path}/:referenceId/location-capture`} component={LocationCapture} />
      {/* <Route
        path="*"
        render={() => {
          return 'Page you are requesting is not found.....';
        }}
      /> */}
      <LoginRequireRoute path="*" />
    </Switch>
  );
};

export default InvestorRoutes;
